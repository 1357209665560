import React from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"
import "../assets/scss/destination.css"

import Header from '../components/header'
import Footer from '../components/footer'

import header1 from "../assets/img/header2.jpg"

import countries from '../data/staysFull'

const Cities = ({ location }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoTraveling - Les villes</title>
        <meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
      </Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="image_bg" style={{backgroundImage: `url(${header1})`}}></div>
        <div class="page_content destinations-page">
          <div class="wrap">
            <div class="wrap_float">
              <div class="section-subtitle">Voici les villes que nous proposons</div>
              <div class="main">
                <div class="popular_destination__slider">
                  {countries.map(country => country.cities.map((city, index) => <div class="slide_item" key={`city-${index}`}>
                    <a href={`/stays`} class="slide_item_img">
                      <div class="sq_parent">
                        <div class="sq_wrap">
                          <div class="sq_content" style={{backgroundImage: `url(${city.images.background})`}}></div>
                        </div>
                      </div>
                    </a>
                    <a href={`/stays`} class="slide_item_content">
                      <div class="flag">
                        <img src={country.images.flag} alt={country.name} />
                      </div>
                      <h3 class="slide_title">
                        {city.name}
                      </h3>
                      <p class="slide_text">
                        {city.description.slice(0, 140)}...
                      </p>
                    </a>
                    <div class="slide_footer">
                      <div class="hours">{`${city.stays.length} séjour${city.stays.length > 1 ? 's' : ''}`}</div>
                      <div class="tours_link">
                        <a href={`/stays`}>Voir les séjours</a>
                      </div>
                    </div>
                  </div>))}
                </div>
              </div>
            </div>
          </div>
        </div>
				<Footer pathname={location.pathname} />        
      </div>
    </main>
  )
}

export default Cities
